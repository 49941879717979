/* custom scroll style */
/* width */
::-webkit-scrollbar {
    width: 10px;              /* width of vertical scrollbar */
    height: 7px;              /* height of horizontal scrollbar ← You're missing this */
    scroll-behavior: smooth;
    direction: ltr;
  }
  ::-moz-scrollbar {
    width: 10px;              /* width of vertical scrollbar */
    height: 7px;              /* height of horizontal scrollbar ← You're missing this */
  }
  
  /* track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-moz-scrollbar-track {
    background: transparent;
  }
  
  /* handle */
  ::-webkit-scrollbar-thumb {
    background: #3e79f7c7;
    border-radius: 10px; /* set the border radius here */
  
  }
  ::-moz-scrollbar-thumb {
    background: #3e79f7c7;
    border-radius: 10px; /* set the border radius here */
  
  }
  
  /* handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #5187fac7;
  }
  ::-moz-scrollbar-thumb:hover {
    background: #3e79f7c7;
  }
  .rc-virtual-list-holder {
    overflow-y: overlay !important;
  }
  
  /* custom scroll style */

  .table-print-moulde-info tr{
    line-height: 20px !important;
  }